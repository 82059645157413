<template lang="pug">
h2 PRESS!
</template>

<script>
export default {
	name: 'PRESS'
}
</script>

<style lang="scss">
</style>